import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Box, Span } from "../Core";
import Link from 'gatsby-link';
import imgL1Logo from "../../assets/image/svg/dermamina-logo.svg";
import Himage from '../../assets/image/jpeg/bg7.jpg';


const Secti = styled.section`
  position: relative;
  border-top:1px solid #e1e1e1;
  background-color: #f7f7fb;
  @media screen and (max-width: 767px) {
  
  }
`;


const Imagi = styled.div`
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  background: url(${Himage}) no-repeat;
  background-size: cover;
  background-position: top center;
  background-position: 0px 0px;
  opacity: 0.04;
`;

const TitleStyled = styled(Title)`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.5px;
  margin-bottom: 0;
  color: #1a1a1a;
  cursor: default; /* Default cursor for larger screens */
  display: flex;
  justify-content: space-between; /* Space between title and icon */
  align-items: center;

  @media screen and (max-width: 1024px) {
    cursor: pointer; /* Pointer cursor for mobile and tablet screens */
    padding: 5px 0; /* Padding for better spacing on mobile */
  }
`;

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: none; /* Default max-height for larger screens */
  overflow: visible; /* Default overflow for larger screens */
  border-top: none; /* Default border for larger screens */

  @media screen and (max-width: 1024px) {
    max-height: ${({ isOpen }) => (isOpen ? "300px" : "0")};
    overflow: hidden;
    transition: max-height 0.3s ease;
    padding-top: 0px; /* Adjust padding for better spacing */
  }

  li {
    line-height: 2;
    a {
      color: #1a1a1a;
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`;

const AccordionItemContainer = styled.div`
  @media screen and (max-width: 1024px) {
    border-top: 1px solid #e1e1e1; /* Adding border-top for mobile view */
    padding: 10px 0; /* Adding padding to the top for better spacing */
    ${({ isLast }) => isLast && `
      border-bottom: 1px solid #e1e1e1; /* Adding border-bottom for the last item */
    `}
  }
`;

const Icon = styled.span`
  font-size: 24px; /* Increase the font size for better visibility */
  color: #1a1a1a;
  transition: transform 0.3s ease;

  @media screen and (min-width: 1025px) {
    display: none; /* Hide the icon on larger screens */
  }

  ${({ isOpen }) => isOpen ? `
    content: '-';
  ` : `
    content: '+';
  `}
`;


const CopyRightArea = styled.div`

  padding: 15px 0;
  p {
    color: ${({ dark, theme }) =>
      dark ? theme.colors.lightShade : theme.colors.darkShade};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: #1a1a1a;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`;
const AccordionItem = ({ title, children, isOpen, onClick, isLast }) => {
    const ref = useRef(null);
  
    useEffect(() => {
      if (isOpen && ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }, [isOpen]);
  
    return (
      <AccordionItemContainer ref={ref} isLast={isLast}>
        <TitleStyled onClick={onClick} className="accordion-title">
          {title}
          <Icon isOpen={isOpen}>{isOpen ? '-' : '+'}</Icon>
        </TitleStyled>
        <UlStyled isOpen={isOpen} className="accordion-content">
          {children}
        </UlStyled>
      </AccordionItemContainer>
    );
  };
  


const Footer = ({ isDark = true }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
    <Secti className="pb-5">
      <Box bg="#f7f7fb" className="">
        <Container>
          <Box
            css={`
              padding: 40px 0 20px;
            `}
          >
            <Row className="justify-content-center">
              <Col lg="3" md="4" xs="12">
                <Link to="/">
                  <img src={imgL1Logo} alt="" width="130px" />
                </Link>
              </Col>
              <Col lg="9" md="8" xs="12" className="mt-5 mt-lg-0">
                <Row>
                  <Col xs="12" lg="3" className="mb-lg-3">
                    <AccordionItem
                      title="Face"
                      isOpen={openIndex === 0}
                      onClick={() => handleAccordionClick(0)}
                    >
                      <li>
                        <Link to="/non-surgical-nose-job">
                          <Span color="#999999" fontSize="12px">
                            Non-Surgical Rhinoplasty
                          </Span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/filler-packages">
                          <Span color="#999999" fontSize="12px">
                            Filler Packages
                          </Span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/lip-enhancements">
                          <Span color="#999999" fontSize="12px">
                            Lip Filler
                          </Span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/chin-filler">
                          <Span color="#999999" fontSize="12px">
                            Chin Filler
                          </Span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/smile-line">
                          <Span color="#999999" fontSize="12px">
                            Smile Lines
                          </Span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/anti-wrinkle-treatments">
                          <Span color="#999999" fontSize="12px">
                            Anti-Wrinkle
                          </Span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/nose-job">
                          <Span color="#999999" fontSize="12px">
                            Nose Job
                          </Span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/polynucleotides">
                          <Span color="#999999" fontSize="12px">
                          Polynucleotides
                          </Span>
                        </Link>
                      </li>
                    </AccordionItem>
                  </Col>
                  <Col xs="12" lg="3" className="mb-lg-3">
                    <AccordionItem
                      title="Hair"
                      isOpen={openIndex === 1}
                      onClick={() => handleAccordionClick(1)}
                    >
                         <li>
                        <Link to="/hair-restoration-london">
                          <Span color="#999999" fontSize="12px">
                            PRP Hair Loss
                          </Span>
                        </Link>
                      </li>

    <li>
                        <Link to="/exosomes-hair-loss">
                          <Span color="#999999" fontSize="12px">
                            Exosomes Hair Loss
                          </Span>
                        </Link>
                      </li>

                     
                      <li>
                        <Link to="/hair-loss">
                          <Span color="#999999" fontSize="12px">
                            Hair Loss
                          </Span>
                        </Link>
                      </li>
                     
                    </AccordionItem>
                  </Col>

                  <Col xs="12" lg="3" className="mb-lg-3">
                    <AccordionItem
                      title="Skin"
                      isOpen={openIndex === 2}
                      onClick={() => handleAccordionClick(2)}
                    >
                      <li>
                        <Link to="/profhilo">
                          <Span color="#999999" fontSize="12px">
                            Profhilo
                          </Span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/skinpen-microneedling">
                          <Span color="#999999" fontSize="12px">
                            Skinpen® Microneedling
                          </Span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/acne-peel">
                          <Span color="#999999" fontSize="12px">
                            Peels
                          </Span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/hydrafacial">
                          <Span color="#999999" fontSize="12px">
                            HydraFacial
                          </Span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/mesotherapy">
                          <Span color="#999999" fontSize="12px">
                            Mesotherapy
                          </Span>
                        </Link>
                      </li>
                    </AccordionItem>
                  </Col>


                  <Col xs="12" lg="3" className="d-lg-none">
                    <AccordionItem
                      title="Body"
                      isOpen={openIndex === 3}
                      onClick={() => handleAccordionClick(3)}
                    >
                       <li>
                        <Link to="/fat-dissolving">
                          <Span color="#999999" fontSize="12px">
                            Fat Dissolving
                          </Span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/laser-hair-removal">
                          <Span color="#999999" fontSize="12px">
                            Laser Hair Removal
                          </Span>
                        </Link>
                      </li>
                    </AccordionItem>
                  </Col>

                



                  <Col xs="12" lg="3" className="mb-lg-3">
                    <AccordionItem
                      title="Contact"
                      isOpen={openIndex === 4}
                      onClick={() => handleAccordionClick(4)}
                      isLast={true}
                    >
                      <li>
                        <a href="mailto:info@dermamina.com">
                          <Span color="#999999" fontSize="12px">
                            info@dermamina.com
                          </Span>
                        </a>
                      </li>
                      <li>
                        <a href="Tel:0207 099 6650">
                          <Span color="#999999" fontSize="12px">
                            0207 099 6650
                          </Span>
                        </a>
                      </li>
                      <li>
                        <a href="/#" target="_blank">
                          <Span color="#999999" fontSize="12px">
                            114 New Cavendish Street, London, W1W 6XT
                          </Span>
                        </a>
                      </li>
                   <br />
                      <li>
                        <Link to="/become-a-model">
                          <Span color="#999999" fontSize="12px">
                            Become A Model
                          </Span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/cosmetics-influencer">
                          <Span color="#999999" fontSize="12px">
                            Calling All Influencers
                          </Span>
                        </Link>
                      </li>
                    </AccordionItem>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Box>
         
          <CopyRightArea>
            <Row className="align-items-center">
              <Col sm="6" className="text-sm-left text-center mb-sm-0">
                <p>&copy; 2024 Dermamina, All Rights Reserved.</p>
              </Col>
              <Col sm="6" className="text-sm-right text-center pt-2">
                <ul className="social-icons">
                  <li>
                    <a href="https://www.instagram.com/dermamina" target="_blank">
                      <i className="icon icon-logo-instagram"></i>
                    </a>
                  </li>
                </ul>
                <p>
                  <Link to="/privacy-policy">
                    <Span className="pr-2" color="#999999" fontSize="12px">
                      Privacy
                    </Span>
                  </Link>
              
                  <Link to="/terms">
                    <Span className="pr-2" color="#999999" fontSize="12px">
                      Terms
                    </Span>
                  </Link>
                  
                  <Link to="/contact">
                    <Span className="pr-2" color="#999999" fontSize="12px">
                      Complaints
                    </Span>
                  </Link>
                  
                  <Link to="/areas-we-serve">
                    <Span color="#999999" fontSize="12px">
                      Areas We Serve
                    </Span>
                  </Link>
                </p>
              </Col>
            </Row>
          </CopyRightArea>
        </Container>
      </Box>
      </Secti>
    </>
  );
};

export default Footer;
